@use '../../styles/vars' as *;

.example__inner {
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin-bottom: 64px;

    &:last-child {
        margin-bottom: 0;
    }
}

// bot illustration
.example__img {
    width: 40%;
}

// wrapper for the text
.example__text {
    width: 50%;
}

// list
.example__text .list {
    margin-bottom: 16px;
}

// QR code
.example__qr {
    width: 100px;
    margin-top: 8px;
}

// alternative section
.example--alt {
    background-color: #bdbdff;

    .example__inner {
        flex-direction: row-reverse;
    }
}

// screen adaptation
// tablet
@media (max-width: $tablet) {
    .example .example__inner {
        flex-direction: column-reverse;
    }

    // bot illustration
    .example__img {
        width: 60%;
        margin: 0 auto;
    }

    // wrapper for the text
    .example__text {
        width: 80%;
        margin-bottom: 48px;
    }
}

// phone
@media (max-width: $phone) {
    // bot illustration
    .example__img {
        width: 100%;
    }
}
