@use '../../styles/vars' as *;

// <nav>
.navigation {
    background-color: #fff;
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.5);
    position: sticky;
    top: -1px;
    z-index: 100;
}

// <ul>
.navigation__inner {
    display: flex;
    justify-content: center;
    column-gap: 16px;
    padding: 4px 0;
    overflow: auto;

    li {
        flex-shrink: 0;
    }
}

// <a> anchor link
.navigation__link {
    display: block;
    padding: 4px 8px;
    background-color: #f1f1f1;
    border: 2px solid #f1f1f1;
    border-radius: 16px;
    transition: $transition;
    transition-property: color, background-color, border-color;
    font-size: 14px;
    outline: none;

    &:hover,
    &:focus {
        border-color: #000;
    }

    // active link
    &.navigation__link--active {
        color: #fff7e3;
        background-color: #000;
    }
}

// screen adaptation
// tablet
@media (max-width: $tablet) {
    // <ul>
    .navigation__inner {
        justify-content: start;
    }
}
