// screen sizes
$laptop: 1280px;
$tablet: 992px;
$phone: 480px;

// gradients
$gradient-1: linear-gradient(217deg, rgba(187, 215, 105, 0.8), rgba(255, 0, 0, 0) 50.71%), linear-gradient(127deg, rgba(89, 89, 255, 0.8), rgba(0, 255, 0, 0) 70.71%),
    linear-gradient(336deg, rgba(199, 191, 240, 0.8), rgba(0, 0, 255, 0) 70.71%);

$transition: 0.2s ease-out;
