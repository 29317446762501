@use '../../styles/vars' as *;

.footer {
    color: #fff;
    background-color: #000;

    // selected text
    ::selection {
        color: #000;
        background-color: #fff7e3;
    }
}

.footer__inner {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 64px;
    row-gap: 32px;
}

// title of the column
.footer__title {
    margin-bottom: 16px;
}

// link
.footer__link {
    display: inline-block;
    width: fit-content;
    margin-bottom: 8px;

    &:hover,
    &:focus {
        text-decoration: underline;
    }

    &.footer__link--phone {
        margin-bottom: 0;
    }
}

// wrapper for the social links
.footer__social {
    display: flex;
    gap: 16px;

    // link
    a {
        transition: $transition;

        &:hover,
        &:focus {
            opacity: 0.75;
        }
    }

    // icon
    svg {
        display: block;
        width: 36px;
    }
}

// copyright section
.copyright {
    display: flex;
    align-items: start;
    gap: 16px;
    margin-top: 64px;
}

// copyright logotypes
.copyright__images {
    display: flex;
    column-gap: 8px;

    img,
    svg {
        display: block;
        width: 72px;
    }
}

.copyright .paragraph {
    margin-top: 0;
}

// screen adaptation
// tablet
@media (max-width: $tablet) {
    .footer__inner {
        grid-template-columns: 1fr;
    }

    // copyright section
    .copyright {
        flex-direction: column;
        margin-top: 32px;
    }
}
