@use '../styles/vars' as *;

// title
.title {
    margin-bottom: 24px;
    font-family: 'e-Ukraine Head';
    font-size: 38px;
    font-weight: 300;

    &.title--sm {
        font-size: 28px;
    }
}

// paragraph
.paragraph {
    max-width: 800px;
    font-weight: 400;

    & + .paragraph {
        margin-top: 16px;
    }

    // large text
    &.paragraph--lg {
        font-size: 18px;
    }

    // small text
    &.paragraph--sm {
        font-size: 14px;
    }
}

// link
.link {
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}

// list
.list-title {
    margin-bottom: 8px;
}

.list__item {
    margin-bottom: 8px;
    padding-left: 16px;
    position: relative;

    &::before {
        content: '';
        width: 6px;
        height: 6px;
        background-color: #000;
        border-radius: 50%;
        position: absolute;
        top: 6px;
        left: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

// screen adaptation
@media (max-width: $tablet) {
    // title
    .title {
        font-size: 34px;

        &.title--sm {
            font-size: 24px;
        }
    }
}

// phone
@media (max-width: $phone) {
    // title
    .title {
        font-size: 30px;

        &.title--sm {
            font-size: 20px;
        }
    }
}
