@use './_vars.scss' as *;
@use './typograpy.scss';

*,
*::before,
*::after {
    /* reset default styles */
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
    /* set common styles */
    color: inherit;
    font-family: 'e-Ukraine';
    line-height: 135%;
    text-wrap: balance;
}

// selected text
::selection {
    color: #fff7e3;
    background-color: #000;
}

html {
    scroll-behavior: smooth;
}

body {
    display: flex;
    flex-direction: column;
    color: #000;
}

// general <section>
.section {
    padding: 64px 0;
}

// main size container
.container {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 80px;
}

// screen adaptation
// tablet
@media (max-width: $tablet) {
    // main size container
    .container {
        padding: 0 64px;
    }
}

// phone
@media (max-width: $phone) {
    // main size container
    .container {
        padding: 0 16px;
    }
}
