@use '../../styles/vars' as *;

.intro {
    background-image: url('../../assets/images/gradient/diia_gradient_01.webp');
    background-size: cover;
}

.intro__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 40px;
    min-height: 100vh;
    padding: 80px 0;
    text-align: center;
}
